@font-face {
  font-family: 'icomoon';
  src:  url('../../public/fonts/icomoon.eot?roc3e5');
  src:  url('../../public/fonts/icomoon.eot?roc3e5#iefix') format('embedded-opentype'),
    url('../../public/fonts/icomoon.ttf?roc3e5') format('truetype'),
    url('../../public/fonts/icomoon.woff?roc3e5') format('woff'),
    url('../../public/fonts/icomoon.svg?roc3e5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-book:before {
  content: "\e919";
}
.icon-calendar:before {
  content: "\e91a";
}
.icon-download:before {
  content: "\e9c7";
}
.icon-language:before {
  content: "\e91b";
}
.icon-pencil:before {
  content: "\e91c";
}
.icon-billing-history:before {
  content: "\e900";
}
.icon-buildings:before {
  content: "\e901";
}
.icon-bullseye:before {
  content: "\e902";
}
.icon-change-plan:before {
  content: "\e903";
}
.icon-clock:before {
  content: "\e904";
}
.icon-diamond:before {
  content: "\e905";
}
.icon-file-lines:before {
  content: "\e906";
}
.icon-gear:before {
  content: "\e907";
}
.icon-house:before {
  content: "\e908";
}
.icon-layer-group:before {
  content: "\e909";
}
.icon-list-ul:before {
  content: "\e90a";
}
.icon-lock:before {
  content: "\e90b";
}
.icon-logout:before {
  content: "\e90c";
}
.icon-memo-circle-info:before {
  content: "\e90d";
}
.icon-menu-settings:before {
  content: "\e90e";
}
.icon-message-exclamation:before {
  content: "\e90f";
}
.icon-microphone:before {
  content: "\e910";
}
.icon-my-plan:before {
  content: "\e911";
}
.icon-play:before {
  content: "\e912";
}
.icon-settings-icons:before {
  content: "\e913";
}
.icon-square-list:before {
  content: "\e914";
}
.icon-transcribe-btn-icon:before {
  content: "\e915";
}
.icon-transcriptions:before {
  content: "\e916";
}
.icon-trash:before {
  content: "\e917";
}
.icon-upload:before {
  content: "\e918";
}
