@import url('./assets/fonts.scss');

// Color Vatiables
$white-color: #FFFFFF;
$black: #000000;
$primary-black: #1F1F1F;
$secondary-black: #303030;
$gray-shade-1: #8F8F8F;
$gray-shade-2: #F6F6F6;

$primary-color: #4535CD;
$secondary-color: #A59DE7;

hr {
    border-bottom: 1px solid $gray-shade-2;
    border-top: 0;
}

.primary-text {
    color: $primary-color;
}

.secondary-text {
    color: $secondary-color;
}

.white-text {
    color: $white-color;
}

.primary-title {
    font-weight: 500;
    font-size: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

// html {
//     font-size: 14px;
// }

body {
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    background-color: #f5f5f5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    color: #4535CD;
}

.link {
    font-weight: 500;
}

.wrapper {
    background: #F6F6F6;
}

.w-100 {
    width: 100%;
}

.login-wrapper {
    background: #fff;
    padding: 3rem;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 0 30px rgb(209 209 209 / 34%);
}

.register-form {
    max-width: 600px;

    .ant-row {
        justify-content: space-between;

        .ant-col-12 {
            flex: 0 0 49%;
            max-width: 49%;
        }
    }
}

.login-form {
    margin-top: 1.2rem;
}

.login-header {
    text-align: center;
}

.login-header h1 {
    font-weight: normal;
    font-size: 1.3rem;
}

.site-layout {

    h1,
    h2,
    h3,
    h4 {
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
}

p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

//   Overide Button

a:hover {
    text-decoration: none;
}

:where(.css-dev-only-do-not-override-ft4r4g).ant-btn {
    height: 38px;
}

// Overide Input

.ant-input-affix-wrapper {
    background-color: #F6F6F6;
    padding: 10px 11px;
    border-color: #F6F6F6;

    .ant-input {
        background-color: #F6F6F6;
    }
}

.ant-input {
    background-color: #F6F6F6;
    padding: 10px 11px;
    border-color: #F6F6F6;
    background-color: #F6F6F6;
    color: $secondary-black;
}

label {
    color: $secondary-black !important;
    font-weight: 500;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    // .ant-select-selection-search {
    //     height: 44px !important;
    //     line-height: 44px !important;
    // }
    height: 44px;
    background-color: #F6F6F6;
    border-color: #F6F6F6;
}

.ant-select-selection-search-input {
    height: 44px !important;
    line-height: 44px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 44px !important;
}

span.ant-select-selection-placeholder {
    line-height: 40px !important;
}

// Change Placeholder Color

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8F8F8F;
    opacity: 1;
    font-weight: 500 !important;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8F8F8F;
    font-weight: 500 !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8F8F8F !important;
    font-weight: 500 !important;
}


// Breadcurmbs
.ant-breadcrumb {
    li {
        color: $black !important;
        font-weight: 500;
        font-size: 1rem;
    }
}

//  Breadcurmbs End 

// Start Header
.header-setting-icon {
    padding: 0 1.3rem;
}

.header-transcribe-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-right: 1rem;
}

.ant-dropdown-trigger {
    cursor: pointer;
}

.header-dropdown-menu {
    .ant-icon svg {
        cursor: pointer;
    }

    cursor: pointer;

    .ant-dropdown-menu-item {
        padding: 8px 12px !important;

        .ant-image-img.ant-dropdown-menu-item-icon {
            width: 1.375rem;
        }
    }

    .ant-dropdown-menu-item-disabled {
        .ant-dropdown-menu-title-content {
            font-weight: 500;
            color: #1F1F1F;
        }
    }
}

// End Header
// ************************
//  Upgrade Plan Widget
// *************************
.site-layout {
    margin-left: 19% !important;

    @media (max-width:1366px) {
        margin-left: 21% !important;
    }
}

.left-sidebar {
    flex: 0 0 19% !important;
    max-width: 19% !important;
    min-width: 19% !important;
    width: 19% !important;
    padding: 1.5rem;

    @media (max-width:1366px) {
        flex: 0 0 21% !important;
        max-width: 21% !important;
        min-width: 21% !important;
        width: 21% !important;
    }

    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

// Start Main Menu
.main-menu {
    .ant-menu-item {
        .ant-image {
            margin-right: 1rem;
        }
    }

    .ant-menu-item-group {
        .ant-menu-title-content {
            color: $white-color;
        }

        .ant-menu-item-group-title {
            color: $secondary-color;
            font-size: 12px;
        }
    }

    .ant-menu-item-divider {
        border-top: 1px solid $secondary-black;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

// End Main Menu

.upgrade-plan-widget {
    background-color: $secondary-black;
    padding: 1.25rem;
    border-radius: 8px;
    margin-top: 2rem;

    h3 {
        color: $white-color;
        font-weight: 500;
        margin-top: 0;
    }

    p {
        color: $secondary-color;
    }

    span {
        color: #D6D6D6;
        margin-bottom: 1rem;
        display: inline-block;
    }

    .ant-progress {
        .ant-progress-inner {
            background-color: #474747;
        }

        .ant-progress-text {
            color: $white-color;
            margin-bottom: 0;
        }
    }
}

// Sidebar Footer
.sidebar-footer {
    text-align: center;

    p {
        color: $gray-shade-1;
    }
}

.inner-wrapper {
    background-color: $white-color;
    border-radius: 12px;
    padding: 1.5rem;
}

.transcriptions {
    margin-top: 2rem;
}

// Get Started
.get-started-wrapper {

    h3 {
        font-weight: 500;
        margin-top: 0;
    }

    .get-started-row {
        .get-started-col {
            padding: 1rem;
            border-radius: 12px;

            .get-started-img {
                width: 3.125rem;
                height: 3.125rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                margin-right: 1rem;
            }

            p {
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }


    .color-variant-1 {
        background-color: #F7F7FD;

        .get-started-img {
            background-color: #E8E7F9;
        }
    }

    .color-variant-2 {
        background-color: #FDF7F7;

        .get-started-img {
            background-color: #F9E7E8;
        }
    }

    .color-variant-3 {
        background-color: #FEFAF5;

        .get-started-img {
            background-color: #FDF1E2;
        }
    }
}


// End Get Started

// Record Audio Modal
.record-audio-modal {
    .ant-modal-content {
        padding: 0 !important;

        .ant-modal-header {
            padding: 1.4rem;
            border-bottom: 1px solid #E6E6E6;

            .ant-modal-title {
                font-weight: 500;
                color: $black;
                font-size: 1rem;
            }
        }
    }

    .ant-modal-body {
        padding: 1.4rem;
    }
}

.language-select,
.vocabulary-select {
    .ant-select-selector {
        padding-left: 6rem !important;

        &:before {
            position: absolute;
            content: 'Language: ';
            color: $primary-black;
            left: 12px;
            top: 11px;
            font-weight: 500;
        }
    }
}

.vocabulary-select {
    .ant-select-selector {
        &:before {
            content: 'Vocabulary: ';
        }
    }
}

// End Record Audio Modal


// Start Table
.ant-table-wrapper {
    .ant-table-thead {
        tr {
            th {
                color: $primary-black;
            }
        }
    }

    .ant-table-tbody {
        tr {
            .ant-table-cell {

                &:nth-child(2) {
                    color: $primary-black;
                }
            }
        }
    }
}

// Start Table

// Transcribe Tab

.audio-data-info {
    h4 {
        margin: 0 0 0 1.8rem;
        font-weight: 500;
    }

    .icon {
        color: $primary-color;
        border-radius: 6px;
        padding: 6px;
        margin-right: 0.3rem;
        background-color: #E8E7F9;
        font-size: 1.1rem;
    }

    .icon-pencil {
        background-color: transparent;
        cursor: pointer;
    }

    h3 {
        font-weight: 500;
        margin: 0;
    }
}


.tabs-wrapper {
    padding: 0;
}

.tab-with-icon {
    .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                padding: 1rem;

                .ant-tabs-tab-btn {
                    span:not(.icon) {
                        display: flex;
                        align-items: center;
                    }

                    .icon {
                        margin-right: 1rem;
                        margin-right: 0.4rem;
                    }
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: $primary-black;
                    text-shadow: none;
                    font-weight: 500;

                    .icon {
                        color: $secondary-color;
                    }
                }
            }

            .ant-tabs-ink-bar {
                background: $secondary-color;
            }
        }
    }

    .ant-tabs-content {
        padding: 0 1.5rem 1.5rem 1.5rem;

        .speaker-recorded-list {
            margin-bottom: 1.5rem;
        }
    }
}

// EndTranscribe Tab

// Audio/Video Uploader

.audio-video-uploader {
    display: flex;

    .ant-upload-drag,
    .ant-upload-list {
        width: 50%;
        padding-left: 8px;
        padding-right: 8px;
    }

    .ant-upload-list-text {
        .ant-upload-list-item {
            flex-direction: row-reverse;
            background: #f6f6f6;
            padding: 22px 16px !important;
            border-radius: 8px;
            margin-top: 0;
            margin-bottom: 8px;

            .ant-upload-icon {
                background-image: url('../public/images/circle-check.svg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                border-radius: 100%;
                width: 20px !important;
                height: 20px !important;
                display: block;
                position: relative;

                svg {
                    display: none;
                }
            }
        }
    }
}

.mr-10 {
    margin-right: 10px;
}

// Word Timings Component
.word-timing:hover {
    color: red;
    text-decoration: underline;
    font-size: 18px;
    transition: all 1s;
}

.pricing-cards-container {
    /* styles.css */
    //body {
    //    font-family: Arial, sans-serif;
    //    background-color: #f5f5f5;
    //    margin: 0;
    //    padding: 0;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    height: 100vh;
    //}

    display: flex;
    justify-content: space-around;
    position: relative;

    //.pricing-cards-container {
    //    display: flex;
    //    justify-content: space-around;
    //}

    .pricing-card {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        text-align: center;
        width: 300px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .pricing-card h2 {
        color: #333;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .price {
        color: #007BFF;
        font-size: 20px;
        margin: 10px 0;
    }

    .card-content {
        flex-grow: 1;
        /* Allow content to expand to fill available space */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Center content vertically */
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin: 5px 0;
    }

    button {
        background-color: #007BFF;
        border: none;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        font-size: 16px;
        padding: 10px 20px;
        transition: background-color 0.3s;
        align-self: center;
        /* Center the button horizontally */
    }

    button:hover {
        background-color: #0056b3;
    }
}

.ml-2 {
    margin-left: .5rem !important;
}

.report-bug-container {
    width: 100%;
    height: 80vh;
    padding: 1rem;
    background-color: white;

    h1 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1rem;
    }
}

.record-button:hover {
    .icon-microphone {
        background-color: #c0b3f2;
    }
}

//#mic ::part(wrapper) {
//    --box-size: 10px;
//    background-image:
//            linear-gradient(transparent calc(10px - 1px), blue 10px, transparent 10px),
//            linear-gradient(90deg, transparent calc(10px - 1px), blue 10px, transparent 10px);
//    background-size: 100% 10px, 10px 100%;
//}